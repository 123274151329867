import React from 'react';
import {
  ComponentVerticalSpacing,
  ComponentWrapper,
  Card,
  ProgressBar,
  Collapse,
  Button,
  BUTTON_TYPES,
  Tabs
} from 'day8-react/build/new-components';
import Svg from './Svg';
import { TabsType } from '../../globalTypes';

type PropsType = {
  data: PaymentMethodsType,
  isHomePage?: boolean
}
export type PaymentMethodsType = {
  title: string
  payment_methods: {
    title: string
    description: string
    payments: {
      title: string
      description: string
      percent_1: string
      percent_2: string
    }[]
    items: {
      title: string
      content: string
    }[]
  }[]
}

const Payment: React.FC<PropsType> = ({ data, isHomePage = false }) => {
  const filterOptions = data.payment_methods.map(method => ({
    text: method.title
  }));
  return (
    <div className="tyw-c-how-it-works-payment relative mb-lg" >
      {!isHomePage && (<span className="number">3</span>)}
      <ComponentVerticalSpacing noTop={isHomePage} noBottom={isHomePage}>
        <ComponentWrapper>
          {data?.title && !isHomePage && <h2 className="text-center text-xl">{data.title}</h2>}
          <Tabs className="margin-top-lg" filterOptions={filterOptions} centerOptions>
            {({ activeFilter }: TabsType) => {
              const paymentMethod = data.payment_methods[activeFilter];
              return (
                <div className="max-width-md margin-auto margin-top-md margin-top-xl@md">
                  <div className="flex items-center@sm flex-gap-md flex-column flex-row@sm">
                    <div className="col">
                      <div className="padding-md relative">
                        {!isHomePage && (
                          <div>
                            <Svg name="stroke" className="svg1" fill="#5fcb71" />
                            <Svg name="stroke" className="svg2" fill="#5fcb71" />
                          </div>
                        )}
                        <Card>
                          <h3 className="d8-u-text-uppercase text-sm text-left margin-bottom-md d8-u-ls-sm">{paymentMethod.title}</h3>
                          <p className='text-sm'>{paymentMethod.description}</p>
                          {paymentMethod.payments && paymentMethod.payments.map((payment, i) => (
                            <div key={i} className="margin-bottom-sm">
                              <p className="grid margin-bottom-sm margin-top-sm d8-u-color-contrast-higher text-sm margin-top-lg">
                                <span className="col-10 text-left">
                                  <span className="d8-u-text-bold">{payment.title} · </span>
                                  <span>{payment.description}</span>
                                </span>
                                <span className="col-2 text-right d8-u-text-bold">{payment.percent_2}%</span>
                              </p>
                              <ProgressBar
                                bars={[{ background: 'd8-u-neon-green-bg', value: `${payment.percent_1}%` }, { background: 'd8-u-primary-bg', value: `${payment.percent_2}%` }]}
                              />
                            </div>
                          ))}
                        </Card>
                      </div>
                    </div>
                    <div className="col">
                      {paymentMethod?.items.map(item => (
                        <Collapse key={item.title && item.title} heading={item.title && item.title}>
                          <div dangerouslySetInnerHTML={{ __html: item.content && item.content }} />
                        </Collapse>
                      ))}
                      {!isHomePage && (
                        <div className="text-center margin-top-md">
                          <Button text="View what's included" icon="arrow-right" iconFlip link="/whats-included" buttonType={BUTTON_TYPES.BARE_SECONDARY} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }}
          </Tabs>
        </ComponentWrapper>
      </ComponentVerticalSpacing>
    </div >
  );
};

export default Payment;
